@media screen and (max-width: 1600px) {
  .long-txt-head {
    line-height: 30px;
    width: 40%;
}
 
  .video-sec img,
  .video-sec video {
    border-radius: 20px;
    height: 80vh !important;
    object-fit: cover;
    transition: 0.5s ease;
  }
  .loader-main {
    align-items: center;
    background: #b1b1b1;
    border-radius: 20px;
    height: 80vh !important;
    justify-content: center;
    top: 0;
  }
 
}

@media screen and (max-width: 1500px) {

  .video-sec img,
  .video-sec video {
    border-radius: 20px;
    height: 80vh !important;
    object-fit: cover;
    transition: 0.5s ease;
    width: 100% !important;
  }

  .logo-side img {
    width: 100px;
  }
  .side-menu a {
    font-size: 14px;
  }
  .heading-top h2 {
    font-size: 24px;
  }
  .next-btn-fix {
    width: calc(100% - 270px);
  }
  .cmn-top-fields select {
    max-width: 200px;
  }
  .logo {
    margin-left: 50px;
  }
  .side-menu .side-btm-last {
    margin-top: 20px;
  }
  .product-cont-boxes {
    padding: 20px 27px;
  }
  .product-overview-box {
    padding: 30px 35px;
  }
  .table-spacing-between {
    width: 300px;
    max-width: 100%;
  }
  .top-card-contin .action-box {
    width: auto;
    padding: 10px 18px;
    gap: 10px;
  }
  .view-history {
    padding-left: 24px !important;
  }
  .blue-border {
    font-size: 12px;
    padding: 10px 25px;
  }

  .pink-border {
    font-size: 12px;
    padding: 10px 25px;
  }
}

@media screen and (max-width: 1400px) {
  .video-sec img,
  .video-sec video {
    border-radius: 20px;
    height: 80vh !important;
    object-fit: cover;
    transition: 0.5s ease;
    width: 100% !important;
  }

  .blue-border {
    font-size: 12px;
    padding: 12px 15px;
  }

  .pink-border {
    font-size: 12px;
    padding: 10px 25px;
  }
  .students-dates h3 {
    font-size: 20px;
  }
  .dash-inner-boxes {
    padding: 25px 18px;
  }
  .dashbox-inner-wrap {
    padding: 20px 20px;
  }
  .dashbox-side {
    padding: 20px 20px;
  }
  .dash-inner-boxes h4 {
    margin-top: 10px;
  }
  .cmn-btn a {
    padding: 11px 30px;
  }
  .product-cont-boxes {
    padding: 20px 15px;
  }
  .product-overview-box {
    padding: 28px 25px;
  }
  .logo-side img {
    width: 100px;
  }
  aside {
    width: 225px;
  }
  .right-side-cmn {
    width: calc(100% - 225px);
  }
  .next-btn-fix {
    width: calc(100% - 225px);
  }
  .product-cmn-color .cmn-top-fields select {
    max-width: 100%;
  }
  .toggle-icon {
    left: 180px;
  }
  .property-name-show.customer img {
    width: 100px;
    height: 100px;
  }

  .browse-main ul li img {
    object-fit: cover;
    width: 170px;
    height: 258px;
    border-radius: 20px;
  }
  .browse-main ul li h2 {
    color: #1b2559;
    max-width: 178px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 15px;
  }
  .browse-main ul li p {
    color: #606060;
    font-size: 13px;
    font-weight: 400;
  }
  .class-detail-sec {
    background: #ffffff66;
    padding: 15px 20px;
    margin-bottom: 20px;
    border-radius: 16px;
  }
  .browse-main .col a img {
    width: 100%;
    height: 260px;
    object-fit: cover;
    border-radius: 15px;
  }
  .browse-main .col a img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 15px;
  }
}

@media screen and (max-width: 1300px) {
  .video-sec img,
  .video-sec video {
    border-radius: 20px;
    height: 80vh !important;
    object-fit: cover;
    transition: 0.5s ease;
    width: 100% !important;
  }

  .side-menu .side-btm-space {
    margin-bottom: 10px;
  }
  .side-menu .side-btm-last {
    margin-top: 20px;
  }
  .side-menu a {
    padding: 10px 15px;
  }
  .cmn-btn a {
    padding: 10px 30px;
  }
  .transactions-box {
    padding: 20px 15px;
  }
  .dashbox-inner-wrap {
    padding: 20px 15px;
  }
  .dash-inner-boxes {
    padding: 20px 18px;
  }
  .dash-inner-boxes h4 {
    font-size: 20px;
  }
  .dash-inner-boxes p {
    margin-top: 13px;
  }
  .dash-inner-boxes {
    margin-top: 17px;
  }
  .dashbox-side h2 {
    margin-bottom: 36px;
  }
  .dash-graph img {
    width: 100%;
    height: 100%;
  }
  .transactions-box h2 {
    margin-top: 12px;
    font-size: 20px;
  }
  .cmn-btn a {
    padding: 10px 25px;
  }
  .cmn-top-fields select {
    padding-left: 10px;
    font-size: 13px;
  }
  .login-cmn-box {
    max-width: 700px;
  }
  .login-box-inner-wrap {
    max-width: 550px;
    margin: auto;
    padding: 35px 0;
  }
  .submit {
    margin-top: 30px !important;
  }
  .logo {
    margin-left: 20px;
  }
  .product-cont-boxes h2 {
    font-size: 22px;
  }
  .product-cont-boxes {
    padding: 15px 15px;
  }
  .table-spacing-between {
    width: 150px;
    max-width: 100%;
  }
  .browse-main .col a img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 15px;
  }
}
@media screen and (max-width: 1200px) {
  .table-cmn.table.table-sm {
    overflow-x: scroll;
    width: 975px;
  }
  aside {
    width: 250px;
  }
  .right-side-cmn {
    width: calc(100% - 225px);
  }
  .logo-side img {
    width: 100px;
  }
  .side-menu a {
    font-size: 15px;
  }
  .next-btn-fix {
    position: unset;
    width: 100%;
  }
  table.table-cmn.table.table-sm {
    margin-bottom: 76px;
  }
  .cmn-btn a {
    padding: 10px 15px;
  }
  .transaction-graph img {
    width: 100%;
    height: 100%;
  }
  .dashbox-side {
    padding: 20px 15px;
  }
  .dash-inner-boxes {
    padding: 15px 10px;
    height: auto;
  }
  .dashbox-inner-wrap h2 {
    font-size: 14px;
  }
  .dashbox-side h2 {
    font-size: 14px;
  }
  .cmn-top-fields .col {
    flex: none;
    width: calc(100% / 3);
  }
  .cmn-top-fields select {
    max-width: 100%;
  }
  .product-tab-cmn-btns .buttons {
    padding: 8px 15px;
  }
  .table-spacing-between {
    width: 10px;
    max-width: 100%;
  }
  .cmn-btn.transactions {
    margin-top: 20px;
  }
  aside {
    width: 225px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .transaction-graph img {
    height: 100%;
  }
  .browse-main a {
    display: block;
    width: 200px;
    text-decoration: none;
  }

  .back-btn {
    left: 235px;
  }
}

@media screen and (max-width: 991px) {
  .comn-modal-set .modal-content {
    border-radius: 30px;
    width: 100%;
    height: 700px;
    background-image: linear-gradient(to bottom, #d7eeff, #bbc6ff) !important;
  }
  .user-pro-search input[type="search"] {
    width: 100%;
  }
  .left-side aside {
    left: -280px;
  }
  .right-side-cmn {
    width: 100% !important;
  }
  .left-side.hide aside {
    left: 0;
    width: 250px;
    background: #fff;
  }
  .left-side.hide aside .side-menu a p {
    display: block;
  }
  .left-side.hide .side-menu a svg {
    margin-right: 13px;
  }
  .toggle-icon {
    left: 0;
    background: #fff;
  }
  .pink-border {
    margin-bottom: 20px;
  }
  .class-detail-sec {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .filter-tab {
    margin-top: 85px;
    margin-bottom: 20px;
  }
  .video-content {
    margin-bottom: 15px;
  }
  button.cmn-pink {
    margin-top: 20px;
  }
  .back-btn {
    background: #ffeab1;
    border: 1px solid #ffac33;
    display: inline-block;
    padding: 8px 20px;
    border-radius: 30px;
    font-size: 15px;
    position: unset;
    margin-top: 18px;
    left: 260px;
    color: #000;
    text-decoration: none;
    font-weight: 600;
  }
  .video-scroll-section {
    height: calc(100vh - 167px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 767px) {
  .login-box-inner-wrap {
    max-width: 550px;
    margin: auto;
    padding: 45px 65px;
  }
  .cmn-btn a {
    padding: 10px 20px;
  }
  .cmn-top-fields .col {
    flex: none;
    width: calc(100% / 2);
  }
  .box-center.row {
    height: 100%;
    margin-top: 20px;
    align-items: center;
  }
  .logo {
    margin: auto;
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .login-box-inner-wrap {
    padding: 45px 30px;
  }
  .cmn-top-fields .col {
    flex: none;
    width: 100%;
  }
  .browse-main ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .video-content {
    padding: 10px;
  }
}
