* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h2 {
}

body {
  background-image: linear-gradient(to bottom, #d7eeff, #bbc6ff) !important;
  background-color: #d2e7ff !important;
  font-family: "Raleway", sans-serif !important;
  background-repeat: no-repeat;
}

.form-select:focus {
  border-color: #dee2e6 !important;
  outline: 0;
  box-shadow: none !important;
}

.subtitles-hidden video::cue {
  display: none !important;
  visibility: hidden !important;
  color: transparent !important;
  background: transparent !important;
  text-shadow: none !important;
  font-size: 0 !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  border: none !important;
}

.loader-style {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #ffffff47;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 9;
  flex-direction: column;
}

/* Default subtitle styling */
video::cue {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  padding: 5px;
  border-radius: 5px;
  line-height: 1.4;
  position: relative;
  bottom: 30px; /* Moves the caption slightly up */
}

/* Show subtitles with .subtitles-visible */
.subtitles-visible video::cue {
  color: white !important;
  font-size: 16px !important;
  background: rgba(0, 0, 0, 0.5) !important;
  padding: 4px !important;
  border-radius: 4px !important;
  position: relative;
  bottom: 30px !important; /* Moves captions up */
}
.login-box-inner-wrap h2 {
  color: #2b3674;

  font-size: 30px;
  font-weight: 700;

  line-height: 32px;
  letter-spacing: -0.6px;
}

.form-set {
  display: flex;
  height: 164px;
  flex-direction: column;
  justify-content: center;
}

.login-box-inner-wrap {
  max-width: 500px;
  margin: auto;
  padding: 45px 0;
  min-height: 450px;
}

.login-box-inner-wrap form {
  margin-top: 35px;
}

.login-box-inner-wrap label {
  color: #2b3674;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.form-set input {
  background: #fff;
  height: 42px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 16px;
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#e1ecff, #e1ecff),
    linear-gradient(258deg, #d200a5, #2800ea) !important;
  background-origin: border-box;
  border: 2px double #0000;
}

.form-set input::placeholder {
  font-size: 13px;
  font-weight: 600 !important;
}

.login-box-inner-wrap input::placeholder {
  color: #071523;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  /* 120% */
  letter-spacing: 0.3px;
}

.submit {
  margin-top: 30px !important;
  border: 1px solid #d200a5;
  background: #d200a5;
  font-family: "DM Sans", sans-serif;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 17%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 25px !important;
  font-size: 13px;
  border-radius: 40px;
  font-weight: 400;
  width: 100%;
  height: 42px;
  text-decoration: none;
  margin: auto;
}

.forgot {
  color: #4318ff;
  text-align: center;
  font-size: 12px;
  text-decoration: none;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-family: "DM Sans", sans-serif;
  display: block;
  margin-top: 20px;
}

.login-box-inner-wrap input:focus {
  box-shadow: none;
}

.logo {
  margin-left: 90px;
  margin-top: 20px;
}

.box-center.row {
  height: calc(100vh - 240px);
  align-items: center;
}

aside {
  border-right: 1px solid #d9d9d9;
  background: #ffffff6e;
  width: 240px;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 11111;
}

.logo-side img {
  width: 130px;
  margin: 20px 60px;
}

.side-menu a {
  color: #000;
  display: flex !important;
  font-size: 15px;
  padding: 15px 25px;
  margin: 10px 21px;
  border-radius: 36px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  align-items: center;
}

.side-menu a svg {
  margin-right: 13px;
  width: 17px;
}

.side-menu a p {
  margin: 0;
}

/* .sidebar {
    padding: 0 30px;
} */

.side-menu .side-btm-space {
  margin-bottom: 5px;
}

.side-menu .side-btm-last {
  margin-top: 40px;
}

.main-wrap {
  display: flex;
}

.height-set {
  height: 100%;
}

.right-side-cmn {
  width: calc(100% - 240px);
  position: relative;
  margin-left: auto;
  height: 100vh;
  overflow-y: scroll;
  padding-left: 15px;
}

.ad-height-vid {
  height: 440px;
}

.right-top {
  display: flex;
  justify-content: end;
  align-items: center;
}

.heading-top h2 {
  margin: 0;
  color: #2b3674;
  font-size: 34px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.68px;
}

.heading-top {
  padding: 25px 15px;
}

.right-top hr {
  color: rgba(255, 255, 255, 0.2);
  margin: 1px !important;
  opacity: 1;
}

.cmn-top-fields {
  padding: 20px 7px;
}

.cmn-top-fields input {
  background: #fff;
  border-radius: 0;
  font-size: 14px;
  height: 40px;
  padding-left: 20px;
}

.cmn-top-fields input::placeholder {
  color: #000;

  font-size: 14px;

  font-weight: 400;
  line-height: 24px;
  /* 160% */
  letter-spacing: -0.3px;
}

.cmn-top-fields input:focus {
  box-shadow: none;
  border: 1px solid #c1dece;
}

.table-cmn tr td p {
  margin: 0;
  color: var(--Secondary-Grey-900, #2b3674);
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.28px;
}

.cmn-top-fields select:focus {
  box-shadow: none;
  border: 1px solid #c1dece;
}

.cmn-top-fields select {
  color: #000;

  height: 40px;
  max-width: 270px;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 400;

  line-height: 24px;
  /* 160% */
  letter-spacing: -0.3px;
  border-radius: 0;
}

.cmn-btn a {
  border: 1px solid #071523;
  background: #071523;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 14%);
  color: #fff;
  text-align: center;

  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 33px;
}

.product-tab ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.side-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 170px);
  margin-top: 10px;
}

.product-tab ul li {
  list-style: none;
  padding: 0;
}

.product-tab {
  padding: 12px 10px;
}

.product-tab ul li a {
  color: rgba(7, 21, 35, 0.5);

  font-size: 13px;
  text-transform: uppercase;
  position: relative;
  padding: 15px 0px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-decoration: none;
  margin-right: 35px;
}

.product-tab .active-tab::before {
  content: "";
  position: absolute;
  bottom: 0;
  background: #071523;
  width: 100%;
  height: 2px;
}

.product-tab .active-tab {
  color: #071523 !important;
}

.product-tab ul li a span {
  margin-left: 10px;
}

.show-results {
  color: #000;

  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.select-result span {
  color: #000;

  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.3px;
  display: block;
  padding-right: 20px;
}

.select-result select {
  padding: 4px 10px;
  width: 55px;
  border: 0.5px solid #d8d7ce;
  background: #fff;
  border-radius: 0;
  color: #000;

  font-size: 13px;
  background-repeat: no-repeat;
  background-position: center right 3px;
  font-weight: 400;
  background-image: url("../Images/Expand_down.svg");
  letter-spacing: -0.3px;
}

.select-result select:focus {
  box-shadow: none;
  border: 1px solid #c1dece;
}

/* .results-sec {
  padding: 0px 15px;
} */

.table-cmn tr th {
  padding: 12px 12px;
  color: #a3aed0;

  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  border-style: solid;
  border-color: #e9edf7;
}

.table-cmn tr {
  border-style: none;
  border-color: #fff;
}

.table-cmn tr td {
  padding: 8px 12px;
  color: #2b3674;
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: left;
}

.table-cmn tr td a {
  color: #071523;

  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.table-cmn tr th:first-child {
  padding-left: 23px;
}

.table-cmn tr td:first-child {
  padding-left: 23px;
}

.next-btn-fix {
  background: #fff;
  text-align: end;
  position: fixed;
  width: calc(100% - 270px);
  padding: 20px 40px;
  bottom: 0;
  border-top: 1px solid #e9edf7;
}

.next-btn-fix a {
  padding: 7px 35px;
  border: 1px solid #071523;
  background: #071523;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 14%);
  color: #fff !important;

  font-size: 15px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-decoration: none;
}

.back-white {
  background-color: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.inner-tab-cmn a {
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #40413a;
  text-align: center;

  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 10px 25px;
  margin-left: 15px;
}

.inner-tab-cmn .active {
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
}

.transactions-box {
  margin-top: 16px;
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 20px 30px;
}

.transactions-box h4 {
  color: #071523;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 0;
  letter-spacing: -0.3px;
}

.transactions-box h2 {
  color: #071523;

  font-size: 24px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.56px;
  margin-top: 12px;
}

.transaction-graph img {
  width: 100%;
  height: 224px;
}

.transaction-graph {
  margin-top: 15px;
}

.graph-months {
  display: flex;
  justify-content: space-between;
  max-width: 340px;
  margin: auto;
}

.graph-months h5 {
  color: rgba(65, 65, 65, 0.4);
  font-family: Poppins;
  font-size: 12.171px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.transaction-box-wrap {
  padding: 0 10px;
}

.dashbox-inner-wrap {
  border: 1px solid #6a6d60;
  background: #fff;
  padding: 25px 25px;
}

.dashbox-inner-wrap h2 {
  color: #6a6d60;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.4px;
}

.dashbox-inner-wrap select.form-select {
  border: 1px solid #b9b5ab;
  background: #fff;
  color: #000;

  height: 40px;
  width: 200px;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 400;

  line-height: 24px;
  /* 160% */
  letter-spacing: -0.3px;
  border-radius: 0;
  background-image: url("../Images/Expand_down.svg");
  background-repeat: no-repeat;
  background-position: center right 10px;
}

.dashbox-inner-wrap select.form-select:focus {
  box-shadow: none;
}

.dash-inner-boxes {
  background: #f0efec;
  box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 15%);
  padding: 25px 22px;
  margin-top: 10px;
  min-height: 160px;
}

.dash-inner-boxes h6 {
  color: #071523;

  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.dash-inner-boxes h4 {
  color: #071523;

  font-size: 24px;
  margin-top: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.56px;
}

.dash-inner-boxes p {
  color: #979797 !important;

  font-size: 13px;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 0;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.dashbox-side {
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 25px 25px;
  height: 100%;
}

.dashbox-side h2 {
  color: #071523;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 56px;
  letter-spacing: -0.4px;
}

.dashboard-items {
  margin: 30px 0 10px 10px;
}

.dash-graph img {
  width: 100%;
  height: 500px;
}

.dash-bottom {
  margin-top: 30px !important;
}

.table-cmn.table.table-sm {
  margin-bottom: 64px;
  --bs-table-bg: transparent;
}

.table-responsive {
  overflow-y: scroll;
  height: 262px;
}

.side-menu a:hover {
  background: #a3aed033;
}

.side-menu a.bar_active {
  background: #d200a5;
  color: #ffffff;
  font-size: 14px;
  align-items: center;
}

.heading-top-inner {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 30px 20px 30px 20px;
}

.heading-top-inner h2 {
  margin: 0;
  color: #000;

  font-size: 30px;
  font-weight: 700;

  line-height: 32px;
  letter-spacing: -0.6px;
}

.heading-top-inner p {
  margin: 0;
  color: #071523;

  font-size: 13px;
  margin-top: 3px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.heading-top-inner h4 {
  color: #071523;
  text-align: right;

  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.3px;
  margin: 0;
}

.heading-top-inner h2 {
  margin: 0;
}

.product-tab-cmn-btns .buttons {
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #40413a;

  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  padding: 10px 25px;
  margin-left: 15px;
}

.product-tab-cmn-btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  margin-right: 8px;
}

.product-tab-left a {
  color: #40413a;

  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}

.product-tab-left a svg {
  margin-right: 10px;
}

.product-cmn-tab {
  margin: 10px 0;
}

.product-tab-cmn-btns .active {
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
}

.product-cont-boxes {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 15px 20px;
}

.product-cont-boxes h6 {
  color: #071523;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.product-cont-boxes h2 {
  color: #071523;

  font-size: 26px;
  margin-top: 10px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.56px;
}

.product-cont-boxes {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 20px 35px;
  margin-bottom: 20px;
}

.product-graph img {
  width: 100%;
}

.product-table-top {
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 15px 0;
  margin-top: 20px;
}

.product-table-top h3 {
  color: rgba(32, 33, 36, 0.69);
  text-align: center;

  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}

.table-cmn .total-amount {
  color: #3eaf3f;
  font-weight: 600;
}

.table-cmn .balance {
  color: #000;
  font-weight: 600;
}

h2.property {
  font-weight: 700;
}

.product-overview-box {
  background: #fff;
  padding: 30px 50px;
  border: 0.25px solid #6a6d60;
}

.product-overview-box h2 {
  color: #071523;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 30px;
}

.product-overview-bottom h3 {
  color: #071523;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  letter-spacing: -0.3px;
}

.product-overview-bottom p {
  color: #071523;

  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin: 0;
}

.bottom-down button {
  color: #071523;
  text-align: right;

  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  background: none;
  border: none;
}

.bottom-top button {
  color: #071523;
  text-align: right;

  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  background: none;
  border: none;
}

.product-overview-box .edit-product {
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 17%);
  background: #071523;
  display: block;
  width: 100%;
  color: #fff;
  text-align: center;

  font-size: 13px;
  margin-top: 8px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  border: none;
  padding: 10px 0;
}

.product-overview-bottom .bottom-top {
  margin-bottom: 15px;
}

.product-overview-bottom .bottom-down {
  margin-bottom: 15px;
}

.product-cmn-color {
  background: #d9d9d9;
}

.product-cmn-color hr {
  color: #e9edf7;
  margin: 1px !important;
  opacity: 1;
}

.table-spacing-between {
  width: 500px;
  max-width: 100%;
}

.transact-amount h3 {
  color: rgba(32, 33, 36, 0.69);

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  letter-spacing: -0.3px;
}

.transact-amount span {
  color: rgba(32, 33, 36, 0.69);

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-left: 6px;
}

.product-transactions .transact-amount {
  display: flex;
}

.product-transactions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 10px;
}

.product-transactions .transact-amount:nth-child(2) {
  margin-left: 30px;
}

.heading-top-inner h2 img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 67px;
  margin-right: 10px;
}

.customer-form {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 30px 50px;
  margin-bottom: 30px;
}

.customer-form h2 {
  color: #071523;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 25px;
}

.customer-form label {
  color: #000;

  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.customer-form input {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 10px 10px;
  color: #071523;
  font-size: 13px;
  border-radius: 0;
}

.customer-form input::placeholder {
  color: #071523;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  /* 120% */
  letter-spacing: 0.3px;
}

.customer-form input:focus {
  box-shadow: none;
  border: 0.25px solid #d9d9d9;
}

.profile-products-sec {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 30px 0;
  margin-bottom: 30px;
}

.profile-products-sec h2 {
  color: #071523;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 25px;
  margin-left: 50px;
}

.product-inner h3 {
  color: #071523;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}

.product-inner p {
  color: #071523;

  font-size: 15px;
  font-weight: 400;
  margin-top: 5px !important;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin: 0;
}

.product-inner-right button {
  color: #000;
  text-align: center;

  font-size: 12px;
  font-weight: 400;
  border: none;
  padding: 4px 50px;
  background: #d9d9d9;
}

.product-inner-right p {
  color: #071523;
  text-align: right;

  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-top: 10px;
  margin-bottom: 0;
}

.product-main-box {
  padding: 0 50px;
}

.product-inner-right {
  text-align: end;
}

.product-inner-right .closed-btn {
  background-color: #000;
  color: #fff;
  font-weight: 300;
}

.admin-box-top h3 {
  text-align: right;

  font-size: 13px;
  font-weight: 700;
  color: #071523;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.admin-box-inner p {
  color: #071523;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  text-align: justify;
}

.admin-box-main {
  padding: 0 50px;
}

.admin-box-top h3 span {
  margin: 0 10px;
}

.admin-box-inner textarea {
  border: 0.25px solid #d9d9d9;
  border-radius: 0;
  color: #071523;
  font-size: 13px;
  font-weight: 400;
}

.admin-box-inner textarea:focus {
  box-shadow: none;
  border: 0.25px solid #d9d9d9;
}

.admin-box-inner textarea::placeholder {
  color: #071523;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  /* 120% */
  letter-spacing: 0.3px;
}

.admin-box-main hr {
  margin: 20px 0;
}

.product-actions {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 30px 0;
  margin-bottom: 30px;
}

.product-actions h2 {
  color: #071523;

  font-size: 13px;
  padding-left: 70px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}

.product-action-inner {
  padding: 0 50px;
}

.product-action-inner h3 {
  color: #000;

  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}

.product-action-inner p {
  color: #000;
  text-align: right;

  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.product-actions .add-btn {
  margin-right: 50px;
  border: 1px solid #d9d9d9;
  background: #5f6368;
  color: #fff;

  font-size: 13px;
  font-weight: 400;
  padding: 10px 30px;
}

.cmn-border {
  color: #dadfdd;

  margin: 1px !important;
  opacity: 1;
}

.cmn-background {
  background: #d9d9d9;
}

.table-cmn .total-amount-withdraw {
  color: #e14942;
  font-weight: 600;
}

.form-save-btn button {
  border: 1px solid #d9d9d9;
  background: #5f6368;
  color: #fff;

  padding: 11px 58px;
  font-size: 13px;
  font-weight: 400;
}

.customer-form-new h2 {
  color: #6a6d60;

  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 25px;
  padding: 0 50px;
}

.customer-form-inner {
  background: #fff;
  padding: 30px 50px;
  margin-bottom: 30px;
}

.customer-form-new {
  background-color: #fff;
  border: 1px solid #979797;
}

.customer-form-new label {
  color: #6a6d60;

  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.customer-form-new input {
  background: #fff;
  padding: 10px 10px;
  color: #6a6d60;
  font-size: 13px;
  border-radius: 0;
  border-radius: 16px;
  border: 1px solid var(--Secondary-Grey-500, #e0e5f2);
  height: 50px;
}

.customer-form-new input::placeholder {
  color: #071523;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  /* 120% */
  letter-spacing: 0.3px;
}

.customer-form-new input:focus {
  box-shadow: none;
  border: 1px solid #6a6d60;
}

.customer-form-new select {
  border: 1px solid #6a6d60;
  background: #fff;
  padding: 10px 10px;
  color: #071523;
  font-size: 13px;
  border-radius: 0;
}

.customer-form-new select:focus {
  box-shadow: none;
  border: 1px solid #c1dece;
}

.form-save-btn-new button {
  border: 1px solid #d9d9d9;
  background: #5f6368;
  color: #fff;

  padding: 8px 70px;
  font-size: 13px;
  font-weight: 400;
}

.product-overview-box .edit-product-new {
  background: #fff;
  display: block;
  width: 100%;
  color: #071523;
  text-align: center;

  font-size: 13px;
  margin-top: 8px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  border: none;
  padding: 10px 0;
  border: 0.25px solid #d9d9d9;
}

.member-info-inner h2 {
  margin-bottom: 10px;
}

.member-inner p {
  color: #071523;
  margin-bottom: 4px;

  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 400;
}

.address-main p {
  color: #071523;

  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.member-inner p:first-child span {
  color: #1462d7;
  font-weight: 500;
  text-decoration: underline;
  letter-spacing: -0.3px;
}

.product-overview-box select {
  border: 0.25px solid #d9d9d9;
  border-radius: 0;
  color: #071523;
  text-align: center;

  font-size: 13px;
  font-weight: 700;
  padding: 8px;
  line-height: 24px;
  /* 160% */
  letter-spacing: -0.3px;
}

.product-overview-box select:focus {
  box-shadow: none;
  border: 0.25px solid #d9d9d9;
}

.cmn-btn button {
  background: #d200a5;
  border: 0;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 17%);
  color: #fff;
  text-align: center;

  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 10px 65px;
  border-radius: 20px;
}

.product-action-inner h3 a {
  color: #1462d7;
}

.access-select-box {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  margin-bottom: 8px;
  align-items: center;
}

.access-select-box h3 {
  color: #071523;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  /* 160% */
  letter-spacing: -0.3px;
}

.access-select-box .inner h3 {
  margin-left: 30px;
  width: 23px;
}

.access-select-box .form-check-inline {
  margin-right: 0;
  margin-left: 30px;
}

.access-select-box p {
  margin-bottom: 0;
  color: #000;

  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.access-select-box .form-check-input {
  border-radius: 5px !important;
  border: 1px solid #979797;
  background: #fff;
}

.access-select-box .form-check-input:checked {
  border-radius: 5px;
  border: 1px solid #979797;
  background: #979797;
}

.input-image-show {
  border: 0.25px dashed #979797;
  background: #fff;
  width: 100%;
  height: 133px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.input-image-show p {
  color: #071523;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-bottom: 0;
}

.input-image-show input {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
}

.blog-save-btn {
  text-align: center;
  margin-top: 44px;
}

.blog-save-btn button {
  padding: 12px 65px;
}

.product-overview-box.blog-bottom {
  padding-bottom: 50px;
}

.check-box-inner {
  display: flex;
  margin-right: 20px;
  align-items: center;
}

.check-box-inner form {
  margin-left: 10px;
}

.check-box-inner form input {
  border-radius: 5px !important;
  border: 1px solid #97979785;
  background: #fff;
  width: 20px;
  height: 20px;
}

.check-box-inner form input:checked {
  border-radius: 5px;
  border: 1px solid #979797;
  background: #979797;
}

.create-mail-checks {
  display: flex;
  align-items: center;
}

.customer-form-inner textarea {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 10px 10px;
  color: #071523;
  font-size: 13px;
  border-radius: 0;
}

.customer-form-inner textarea:focus {
  box-shadow: none;
  border: 0.25px solid #d9d9d9;
}

.dash-graph.right {
  margin-right: 12px;
}

.user-img img {
  width: 50px;
  border-radius: 70px;
  height: 50px;
  object-fit: cover;
}

.select-box select {
  width: fit-content;
  border: 1px solid #dee2e6;
  border-radius: 0;
  color: #000;

  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.select-box select:focus {
  box-shadow: none;
  border: 1px solid #dee2e6;
}

.order-btn a {
  border: 1px solid #979797;
  background: #fff;
  color: #979797 !important;
  width: 85px;
  font-size: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.accept-reject-btn button {
  background: linear-gradient(180deg, #1ba97d, #118d67);
  border: 1px solid #1ba97d;
  box-shadow: 0 10px 14px 0 #1ba97d66;
  color: #fff;
  text-align: center;

  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 90px;
  border-radius: 0;
  margin: 0 10px;
}

.accept-reject-btn {
  display: flex;
  justify-content: center;
}

.admin-box-select select {
  border-radius: 0;
  color: #6a6d60;

  font-weight: 600;
  font-size: 14px;
  height: 40px;
  letter-spacing: -0.3px;
  line-height: 24px;
  /* max-width: 270px; */
  padding-left: 20px;
}

.customer-form-inner textarea::placeholder {
  color: #6a6d60;
  font-size: 13px;
  font-weight: 600;
  font-size: 14px;
}

.customer-form-new input input::placeholder {
  color: #6a6d60;
  font-size: 13px;
  font-weight: 600;
  font-size: 14px;
}

.customer-form-inner .admin-box-select select {
  appearance: auto;
}

.admin-box-select select:focus {
  box-shadow: none;
}

.account-btn {
  border: 1px solid #979797 !important;
  background: #fff !important;
  color: #979797 !important;
  color: #fff;
  text-align: center;

  margin: 0 2px !important;
  font-size: 13px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  line-height: normal;
  text-decoration: none;
  padding: 8px 33px !important;
  border-radius: 0 !important;
  margin: 10px 0;
}

.account-btn:hover {
  border: 1px solid #979797;
  background: #fff;
  color: #979797 !important;
}

.theme-colr-btn:hover {
  color: #ffffff !important;
}

.common-colr-btn:hover {
  color: #ffffff !important;
}

.add-btn {
  border: 1px solid #071523;
  background: #071523;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 17%);
  color: #fff;
  text-align: center;

  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 70px;
}

.customer-form-inner-new {
  border: 0.25px solid #d9d9d9;
  padding: 20px 20px;
}

.support-chat-box-main {
  padding: 22px;
  word-wrap: break-word;
  background-clip: initial;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
}

.user-top-hdng {
  display: flex;
  align-items: center;
}

.user-top-hdng img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin-right: 10px;
}

.user-top-hdng h3 {
  font-size: 22px;
  text-transform: capitalize;
  margin-right: 11px;
}

.user-top-hdng h4 {
  font-size: 11px;
  margin: 0;
}

.user-msg-box {
  background: #fbfbfb;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 20px;
  color: #000;
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  margin-left: 35px;
  margin-top: 10px;
  padding: 15px 25px;
}

.user-msg-box p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
}

.support-inner h2 {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
}

.support-msg-box {
  padding: 20px 10px;
  height: 55vh;
  overflow-y: scroll;
}

.user-box {
  margin-bottom: 15px;
}

.send-box {
  bottom: 0;
  position: absolute;
  width: 95%;
}

.send-feild {
  background: #fbfbfb !important;
  border: none !important;
  padding: 15px !important;
}

.send-box input:focus {
  box-shadow: none;
}

.dash-graph {
  border: 0.25px solid #6a6d60;
  background: #fff;
  box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.05);
  padding: 25px 25px;
}

.toggle-icon {
  position: fixed;
  left: 10px;
  width: 40px;
  height: 40px;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 1;
  border-radius: 62px;
  align-items: center;
  cursor: pointer;
}

.toggle-icon img {
  width: 20px;
}

.left-side.hide aside .side-menu a p {
  display: none;
}

.left-side.hide aside {
  width: 100px;
}

.right-side-cmn.hide {
  width: calc(100% - 100px);
  /* background: #eff8f3; */
}

.left-side.hide .side-menu a {
  justify-content: center;
  padding: 15px 12px;
}

.right-side-cmn.hide .next-btn-fix {
  width: calc(100% - 100px);
}

.user-profile-main figure {
  margin: 0;
}

.left-side.hide .side-menu a svg {
  margin: 0;
}

.user-profile-main img {
  width: 150px;
  /* border-radius: 79px; */
  object-fit: cover;
  height: 150px;
}

.user-profile-main h2 {
  padding-bottom: 6px;
  margin: 0;
  color: rgb(0 0 0 / 100%);
  font-size: 20px;
  font-weight: 700;
}

.user-profile-main p {
  margin: 0;
  font-size: 14px;
  padding: 2px 0;
  font-weight: 400;
  color: #40413a;
}

.user-profile-main figcaption {
  margin-left: 20px;
}

.logo-side .small {
  display: none;
}

.left-side.hide .logo-side .small {
  display: block;
}

.left-side.hide .logo-side .big {
  display: none;
}

.user-profile-main {
  padding: 20px 15px;
  background-color: #fff;
  border: 0.25px solid #6a6d60;
  box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.05);
}

.send-box button img {
  transform: rotatey(180deg);
  filter: invert(1);
}

.logo-side .small {
  width: 40px;
  margin: 40px 20px;
}

.user-profile-main p span {
  font-weight: 700;
  color: rgba(7, 21, 35, 0.7);
}

.user-profile-all {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 30px 50px;
  margin-bottom: 30px;
}

.user-profile-all h3 {
  text-align: center;
  color: #6a6d6f;
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
}

.user-profile-all span {
  font-weight: 700;
}

.dash-graph .gender-chart {
  height: 400px !important;
  margin: auto;
  max-width: 400px !important;
}

.product-overview-box p {
  background: linear-gradient(180deg, #1ba97d 0%, #118d67 100%);
  box-shadow: 0px 10px 15px 0px rgba(22, 155, 114, 0.4);
  padding: 10px 10px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 4px;

  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 600;
}

.admin-box-select label {
  color: #6a6d60;
  font-size: 13px;
  font-weight: 700;
}

.dash-graph h6 {
  color: #6a6d60;

  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: -0.4px;
}

.customer-form.profile h2 {
  font-size: 18px;
  font-weight: 600;
}

.property-name-show {
  width: fit-content;
  display: flex;
  align-items: center;

  padding: 10px 12px;
  border-radius: 10px;
  background: linear-gradient(180deg, #6c9459 0%, #39562e 100%);
  box-shadow: 0px 5px 15px 0px rgba(27, 169, 125, 0.2);
  margin-top: 15px;
}

.property-name-show img {
  width: 65px;
  border-radius: 10px;
  height: 64px;
  margin-right: 10px;
}

.property-name-show h2 {
  color: #fff !important;

  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}

.property-name-show h3 {
  color: #fff;

  font-size: 14px;
  opacity: 0.7;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.56px;
}

.property-main h2 {
  color: #071523;
  text-transform: uppercase;

  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.3px;
}

.property-main {
  margin-top: 35px;
  margin-left: 12px;
}

.dashboard-items.profile .dash-inner-boxes {
  margin-top: 10px;
}

.property-name-show h3 {
  margin: 0;
  padding: 0;
  border: 0;
}

.assign {
  border: 1px solid #c1dece;
  padding: 17px 10px !important;
  font-size: 13px !important;
  text-transform: uppercase;
}

.logo img {
  width: 150px;
}

.dash-inner-boxes.vendor {
  margin: 0;
}

.status-box h2 {
  margin: 0;
  color: #fff;
}

.status-box {
  background: linear-gradient(180deg, #1ba97d 0%, #118d67 100%);
  box-shadow: 0px 10px 15px 0px rgba(22, 155, 114, 0.4);
  width: 200px;
  border-radius: 8px;
  padding: 9px 18px;
  margin-top: 20px;
  margin-right: 10px;
}

.status-box p {
  color: #fff;
}

.add-btn-category {
  color: #fff;
  border: 1px solid #071523;
  background: #071523;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 17%);
  width: 97px;
  font-size: 14px;
  height: 36px;
  border-radius: 0;
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.change-pass {
  height: 100%;
  margin-top: 30px;
}

.property-name-show.view h2 {
  margin: 0;
}

.bar_active .analytics-icon path {
  fill: #5f6368 !important;
}

a.menu-btn.bar_active path {
  fill: #fff !important;
}

.analytics-icon path {
  fill: #000 !important;
}

.property-name-show.customer {
  background: none;
  box-shadow: none;
}

.property-name-show.customer h2 {
  color: rgba(7, 21, 35, 0.7) !important;
  font-weight: 600;
}

.property-name-show.customer h3 {
  font-weight: 600;
  color: #979797;
}

.property-name-show.customer img {
  width: 130px;
  height: 115px;
}

.dash-inner-boxes.small {
  height: 100px !important;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.customer-form-new.card h2 {
  color: #071523;

  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.4px;
}

.user-profile-main h3 {
  color: #071523;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 20px;
}

.admin-box-select select:focus {
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.property-name-show.view {
  background: none;
  box-shadow: none;
}

.property-name-show.view h2 {
  color: #3a7563 !important;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
}

.menu-btn.bar_active .dash-only path {
  stroke: none !important;
  fill: #5f6368 !important;
}

.table-cmn.manager img {
  width: 60px;
  height: 60px;
}

.table-cmn td {
  display: table-cell;
  vertical-align: middle;
}

.send-box .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: #b9b5ab;
  border: 0;
  border-radius: var(--bs-border-radius);
}

.support-msg-box {
  -ms-overflow-style: none !important;
  /* for Internet Explorer, Edge */
  scrollbar-width: none !important;
  /* for Firefox */
  overflow-y: scroll;
}

.support-msg-box::-webkit-scrollbar {
  display: none !important;
  /* for Chrome, Safari, and Opera */
}

/* Profile view css  start  */

.user-profile-main hr {
  margin: 30px 0px;
}

.profile-hours {
  display: flex;
  justify-content: space-between;
}

.dlt-ac-btn a {
  border: 1px solid #b9b5ab;
  background: #b9b5ab;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 14%);
  color: #fff;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  padding: 11px 33px;
}

.user-profile-main figcaption input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.user-profile-main figcaption .form-check-input {
  height: 22px;
}

.user-profile-main figcaption .form-switch .form-check-input {
  width: 3em;
  border: var(--bs-border-width) solid #b9b5ab;
}

.user-profile-main figcaption .form-check-input:checked {
  background-color: #b9b5ab;
}

.user-profile-main figcaption .form-switch .form-check-input:focus {
  filter: grayscale(1);
}

/* Profile view css End  */

.default-links ul {
  padding: 0;
  list-style: none;
}

.default-links ul li {
  display: flex;
  border-bottom: 1px solid #b9b5ab6e;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
}

.default-links ul li h6 {
  color: #071523;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.default-links ul li p {
  font-size: 13px;
}

.profile-add-img {
  width: 100% !important;
}

.active-default-links ul {
  padding: 0;
  list-style: none;
}

.active-default-links ul li {
  border-bottom: 1px solid #b9b5ab6e;
  justify-content: space-between;
  padding: 8px 0px;
}

.active-default-links ul li h6 {
  color: rgba(7, 21, 35, 0.7);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 8px;
}

.active-default-links ul li p {
  text-align: end;
  font-size: 13px;
}

.cmn-btn a svg {
  height: 18px;
  width: 18px;
  margin-right: 12px;
}

.cmn-btn a {
  border: 1px solid #071523;
  background: #071523;
  color: #fff;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 33px;
}

.theme-colr-btn {
  background: #979797 !important;
  color: #fff !important;
}

.common-colr-btn {
  background: #071523 !important;
  color: #fff !important;
}

.select-box {
  display: flex;
  justify-content: center;
}

.view-chat a {
  border: 1px solid #b9b5ab;
  background: #b9b5ab;
  color: #fff;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 33px;
}

.modal-close {
  border: 1px solid #b9b5ab !important;
  background: #b9b5ab !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 13px !important;
  font-style: normal !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-decoration: none !important;
  padding: 11px 33px !important;
}

.modal-open-view {
  border: 1px solid #b9b5ab !important;
  background: #071523 !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 13px !important;
  font-style: normal !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-decoration: none !important;
  padding: 11px 33px !important;
}

.no-conversations {
  background: #eaeaea;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-none {
  border: none !important;
}

.chat-box-select select {
  border-radius: 0;
  color: #6a6d60;
  font-weight: 600;
  font-size: 14px;
  height: 45px;
  letter-spacing: -0.3px;
  line-height: 24px;
  width: 350px;
  padding-left: 20px;
}

.sign-banner img {
  width: 330px;
}

.login-box-inner-wrap p {
  color: #a3aed0;
  font-size: 14px;
}

.form-check-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px !important;
}

.form-check-box label {
  font-size: 12px;
  color: #2b3674;
  font-weight: 600;
  font-family: "DM Sans", sans-serif;
  line-height: 0px;
}

.heading-top p {
  color: #707eae;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 5px;
}

.user-side-pro img {
  width: 40px;
}

.user-side-pro {
  border-radius: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
  margin-right: 20px;
  padding: 4px 8px;
}

.top-bar img {
  width: 25px;
  height: 25px;
  object-fit: cover;
}

.bell-icon {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-top: 10px;
}

.user-pro-search {
  /* margin-left: 10px;  */
  position: relative;
  width: 100%;
}

.user-pro-search input {
  border-radius: 49px;
  background: #f4f7fe;
  border: 0;
  padding: 10px 20px;
  font-size: 14px;
}

.user-pro-search input[type="search"] {
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#bbc6ff, #bbc6ff),
    linear-gradient(258deg, #d200a5, #2800ea);
  background-origin: border-box;
  border: 2px double #0000;
  background-repeat: no-repeat;
  padding: 9px 20px 9px 27px;
  border-radius: 10px;
  color: #000;
  font-size: 12px;
  width: 600px;
  font-weight: 700;
}

.user-pro-search input[type="search"]::placeholder {
  color: #000;
}

.user-pro-ulpd a {
  border-radius: 30px;
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
  text-decoration: none;
  color: #fff;
  background: #d200a5;
  padding: 10px 14px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.action-box {
  display: flex;
  border-radius: 20px;
  background: #fff;
  padding: 8px 12px;
  gap: 15px;
}

.action-graph {
  background: #f4f7fe;
  padding: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.action-graph-icon p {
  color: #a3aed0;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.28px;
  margin-bottom: 0px;
}

.action-graph-icon h2 {
  color: #2b3674;
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
  margin: 0;
}

.table-box-cmn {
  border-radius: 20px;
  /* 
  margin-top: 20px; */
}

.table-heading h2 {
  color: #2b3674;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.48px;
  margin-bottom: 0;
}

.logo-side {
  border-bottom: 1px solid #f4f7fe;
}

.add-list-icon {
  border-radius: 12px;
  background: #d200a5;
}

.add-list-icon {
  border-radius: 12px;
  background: #d200a5;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.customer-form-new {
  border-radius: 20px !important;
  background: #fff !important;
  border: 0 !important;
}

th span {
  position: relative;
  cursor: pointer;
}

th span::after {
  background-image: url("../Images/th-arrow.svg");
  content: "";
  position: absolute;
  left: 120%;
  color: #999;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  z-index: 999;
  top: 5px;
}

th span.asc::after {
  content: "";
  background-image: url("../Images/th-arrow.svg");
}

th span.desc::after {
  content: "";
  background-image: url("../Images/th-arrow.svg");
}

th span:hover::after {
  color: #333;
}

.active-icon {
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
}

.faq-accordon-sec .accordion-body {
  font-weight: 500;
  text-align: left;
  color: #2b3674;
  font-size: 14px !important;
}

.faq-accordon-sec .accordion-header p {
  display: flex;
  flex-direction: column;
  margin: 0;
  text-align: left;
  color: var(--Secondary-Grey-600, #a3aed0);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.28px;
  gap: 5px;
}

.faq-accordon-sec .accordion-header p span {
  color: #2b3674;
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  /* 16px */
  letter-spacing: -0.32px;
}

.faq-accordon-sec .accordion-item {
  margin-bottom: 10px;
  border-radius: 10px !important;
  border: 0 !important;
}

.faq-accordon-sec .accordion-button {
  border-radius: 16px !important;
  background: #ffffff99;
  box-shadow: 0px 18px 40px 0px rgba(112, 144, 176, 0.12);
  border: none !important;
}

.faq-accordon-sec .accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: #fff;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

.faq-accordon-sec .accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: none !important;
}

.manage-co-top-tab .top-head h2 {
  color: #2b3674;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
}

.manage-co-top-tab .top-head h2 span {
  color: #4318ff;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
  margin-left: 10px;
}

.manage-co-top-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manage-tabs-card {
  border-radius: 20px;
  background: var(--Secondary-Primary-White, #fff);
  padding: 15px;
  margin-bottom: 20px;
}

.card-img-box {
  display: flex;
  justify-content: center;
}

.card-img-box img {
  width: 100%;
  border-radius: 20px;
}

.details-box-top h3 {
  color: var(--Secondary-Dark-Grey-900, #1b2559);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.36px;
  margin-bottom: 0;
}

.details-box-top p {
  color: var(--Secondary-Grey-600, #a3aed0);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
  margin-bottom: 0;
}

.details-box-top {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.card-box-deta {
  width: 70%;
}

.card-box-icon {
  width: 30%;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.details-box-top .like-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.details-box-bottom p {
  color: var(--Primary-Purple-Blue-500---Primary, #4318ff);
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.28px;
  margin-bottom: 0;
}

.details-box-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.details-box-bottom a {
  border-radius: 20px;
  color: var(--Secondary-Primary-White, #fff);
  background: #11047a;
  text-decoration: none;
  padding: 8px 30px;
  font-size: 14px;
}

.manage-tabs-viewbox {
  margin-top: 20px;
}

.manage-co-top-tab .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #4318ff;
  background-color: #ffffff;
  border-radius: 30px;
}

.manage-co-top-tab .nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  color: #4318ff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.28px;
}

.top-video-box {
  border-radius: 20px;
  background: var(--Secondary-Primary-White, #fff);
  padding: 20px;
}

.top-video-box h2 {
  color: var(--Secondary-Dark-Grey-900, #1b2559);
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.4px;
}

.top-video-icon {
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
}

.help-popup-new {
  width: 300px;
  transform: translate(1500px, 76px) !important;
  overflow-y: scroll;
  height: 200px;
}

.notify-inner a {
  text-decoration: none;
  color: #000;
}

.notify-inner {
  padding: 10px 15px;
}

.notify-inner a h3 {
  font-size: 16px;
}

.list-progress .progress-bar {
  background-color: #4318ff;
  height: 10px;
}

.list-progress .progress {
  height: 10px;
}

.comn-modal-set.modal .input-set {
  background-color: #bbc6ff;
  border-radius: 16px;
  height: 45px;
  font-size: 13px;
  font-weight: 600;
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#bbc6ff, #bbc6ff),
    linear-gradient(258deg, #d200a5, #2800ea);
  background-origin: border-box;
  border: 2px double #0000;
  color: #000;
  line-height: 100%;
  letter-spacing: 0;
}

.comn-modal-set.modal select {
  background-color: #bbc6ff;
  border: 2px solid #8d00c1 !important;
}

.comn-modal-set.modal .input-set::placeholder {
  color: #000;
  font-weight: 600;
}

.comn-modal-set.modal label {
  color: #2b3674;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.28px;
}

.comn-modal-set.modal textarea {
  border-radius: 16px;
  border: 1px solid var(--Secondary-Grey-500, #e0e5f2);
  color: #000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.28px;
  resize: none;
}

.comn-modal-set.modal textarea::placeholder {
  color: #000;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: none !important;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none !important;
}

.comn-modal-set.modal select {
  border-radius: 16px !important;
  border: 1px solid var(--Secondary-Grey-500, #e0e5f2);
  height: 50px !important;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.28px;
  appearance: none !important;
}

.upld-vd-box {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.upld-img-side {
  border-radius: 13px;
  border: 1px dashed var(--Secondary-Grey-500, #e0e5f2);
  background: var(--Secondary-Grey-100, #fafcfe);
  padding: 20px;
  text-align: center;
  width: 300px;
}

.upld-img-side svg {
  width: 70px;
}

.upld-img-side .upld-text h3 {
  color: var(--Primary-Purple-Blue-500---Primary, #4318ff);
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.4px;
}

.upld-img-side .upld-text p {
  color: var(--Secondary-Dark-Grey-500, #8f9bba);
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.upld-btn-side h2 {
  color: #2b3674;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
}

.upld-btn-side p {
  color: var(--Secondary-Grey-600, #a3aed0);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.32px;
}

.upld-btn-side a {
  background-color: #4318ff;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  border-radius: 10px;
}

.comn-modal-set .modal-body {
  padding: 10px 50px;
}

.upd-vd-more {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  margin-top: 30px;
}

.upd-vd-more p {
  color: #2b3674;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.28px;
  margin-bottom: 0px;
}

.upd-vd-more a {
  border-radius: 12px;
  background: var(--Secondary-Grey-300, #f4f7fe);
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
}

.modal-f-btn {
  background: #d200a5 !important;
  border: 0 !important;
  padding: 10px 40px !important;
  border-radius: 50px !important;
  font-size: 14px !important;
}

.comn-modal-set .modal-title {
  margin-bottom: 0;
  color: #2b3674;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
  /* padding-left: 30px; */
  margin-bottom: 10px;
  margin-top: 10px;
}

.back-page-title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.back-page-title a {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 8px 10px;
}

.school-profile-detail {
  border-radius: 20px;
  background: var(--Secondary-Primary-White, #fff);
  padding: 10px;
  text-align: center;
}

.qr-code img {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.school-profile-detail p {
  color: var(--Secondary-Grey-600, #a3aed0);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.28px;
}

.heavy-text {
  color: #2b3674 !important;
  text-align: center;
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 32px !important;
  letter-spacing: -0.4px !important;
}

.three-detail {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 15px 0px;
}

.info-about-school .heavy-text {
  color: #2b3674;
  text-align: center;
  font-size: 24px !important;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.48px;
}

.action-school-btns {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.action-school-btns a {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  width: 170px;
  border-radius: 10px;
  text-decoration: none;
  color: #4318ff;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: -0.28px;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-center-text {
  color: var(--Secondary-Grey-600, #a3aed0);
  text-align: center;

  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: -0.28px;
  margin-bottom: 8px;
}

.heading-top span {
  color: #4318ff;
  margin-left: 10px;
}

.teacher-search-history {
  border-radius: 20px;
  background: var(--Secondary-Primary-White, #fff);
  padding: 15px;
  /* text-align: center; */
  margin-top: 20px;
}

.teacher-search-history h2 {
  color: #2b3674;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
}

.search-history-list h4 {
  color: #2b3674;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.28px;
}

.search-history-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.search-history-list p {
  color: #a3aed0;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.28px;
}

.viewing-h-pro img {
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.viewing-h-pro {
  display: flex;
  gap: 10px;
  align-items: center;
}

.viewing-h-pro h5 {
  color: var(--Secondary-Grey-900, #2b3674);
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.32px;
  margin-bottom: 0;
}

.search-history-list.collect-box {
  border-radius: 16px;
  background: var(--Secondary-Primary-White, #fff);
  box-shadow: 0px 18px 40px 0px rgba(112, 144, 176, 0.12);
  padding: 20px;
}

a.search-history-list.collect-box {
  text-decoration: none;
}

.display-table {
  display: table;
}

.table-cell {
  display: table-cell;
}

.top-card-contin {
  display: flex;
  gap: 20px;
}

.top-card-contin .action-box {
  width: 100%;
}

.action-graph.gradi-circle {
  background: linear-gradient(90deg, #4481eb 0%, #04befe 100%);
}

.sign-shape {
  background-image: url("../Images/right-banner-login.png");
  background-repeat: no-repeat;
  height: 100vh;
  background-position: right;
  display: flex;
  justify-content: center;
  background-size: contain;
  align-items: center;
}

.filter-tab ul {
  padding: 0;
  display: flex;
  list-style: none;
  gap: 7px;
  margin: 0;
  flex-wrap: wrap;
}

.filter-tab {
  margin-top: 100px;
}

.filter-tab ul .active-tab {
  background: #d200a5;
  color: #fff;
  border-color: #d200a5;
}

.filter-tab ul li {
  background: #ffeab1;
  border-radius: 37px;
  font-size: 13px;
  padding: 6px 20px;
  display: flex;
  cursor: pointer;
  border: 1px solid #ffac33;
  align-items: center;
  font-weight: 600;
}

.filter-tab-slide .carrier-options {
  display: flex;
  align-items: center;
  gap: 15px;
}

.carrier-options h3 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.carrier-options img {
  width: 25px;
}

.browse-main ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
}

.browse-main {
  margin-top: 20px;
}

.browse-main ul li h2 {
  color: #1b2559;

  max-width: 200px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 15px;
}

.browse-main ul li p {
  color: #606060;
  font-size: 15px;
  font-weight: 400;
}

.browse-main ul li a {
  text-decoration: none;
  display: block;
}

.browse-main ul li {
  list-style: none;
}

.browse-main a h2 {
  color: #1b2559;

  max-width: 200px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 15px;
}

.browse-main a p {
  color: #606060;
  font-size: 13px;
  font-weight: 400;
}

.browse-main a {
  display: block;
  width: 100%;
  text-decoration: none;
  position: relative;
}

.browse-main a img {
  width: 100%;

  object-fit: cover;
  border-radius: 15px;
}

.cmn-gradient {
  height: 100vh;
  background-image: linear-gradient(to bottom, #d7eeff, #bbc6ff) !important;
  overflow-y: scroll;
}

.cmn-yellow-btn button {
  background: #ffeab1;
  border-radius: 37px;
  font-size: 14px;
  padding: 6px 20px;
  display: flex;
  border: 1px solid #ffac33;
  align-items: center;
  font-weight: 600;
}

.back-btn {
  background: #ffeab1;
  border: 1px solid #ffac33;
  display: inline-block;
  padding: 8px 20px;
  border-radius: 30px;
  font-size: 15px;
  position: fixed;
  left: 260px;
  color: #000;
  text-decoration: none;
  font-weight: 600;
}

.back-btn svg {
  margin-right: 10px;
}

.video-content h2 {
  font-size: 14px;
  font-weight: 700;
  color: #1b2559;
}

.video-content p {
  margin: 0;
  font-size: 14px;
  color: #606060;
}

.video-content h6 {
  color: #606060;
  margin: 20px 0;
  font-size: 14px;
}

.video-content span {
  color: #606060;
  display: flex;
  font-weight: 500;
  gap: 10px;
  font-size: 14px;
}

.video-sec img {
  width: 100%;
}

/* .video-side-btns button {
  margin-bottom: 6px;
  width: 40px;
  height: 40px;
  box-shadow: rgb(0 0 0 / 7%) 0px 3px 8px;
  border: none;
  border-radius: 33px;
} */

.view-reels-side-btn {
  margin-bottom: 6px;
  width: 40px;
  height: 40px;
  box-shadow: rgb(0 0 0 / 7%) 0px 3px 8px;
  border: none;
  border-radius: 33px;
}

.btns-main p {
  color: #000000;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
}

button.cmn-yellow {
  background: #ffeab1;
  border-radius: 37px;
  font-size: 15px;
  padding: 6px 15px;
  display: flex;
  cursor: pointer;
  border: 1px solid #ffac33;
  align-items: center;
  font-weight: 600;
width: 100%;
}

.video-player > div {
  width: 100% !important;
  height: 100% !important;
}

.modal-right {
  display: flex;
  gap: 10px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.modal-right .menu-dots {
  background: #fff;
  border: none;
  padding: 6px 10px;
  border-radius: 10px;
}

.height-100 .modal-content {
  height: 100% !important;
}

.comn-modal-set .modal-content {
  border-radius: 30px;
  width: 962px;
  height: 100%;
  background-image: linear-gradient(to bottom, #d7eeff, #bbc6ff) !important;
}

.comn-modal-set .modal-content .collection-sec {
  height: 540px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.comn-modal-set .modal-content .height-480 {
  height: 480px;
}

.comn-modal-set .modal-content .collection-sec::-webkit-scrollbar {
  display: none;
}

.collection-video-inner img {
  height: 80px;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.collection-video-inner h3 {
  color: #2b3674;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.collection-video-inner p {
  font-size: 12px;
  color: #a3aed0;
}

.collection-video-inner {
  border: 4px solid transparent;
  box-shadow: 0px 18px 40px 0px #7090b01f;
  background: #ffffff45;
  padding: 10px 10px;
  border-radius: 15px;
  position: relative;
}

.collection-video-inner.active-box {
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#d7e4ff, #dae7ff),
    linear-gradient(258deg, #d200a5, #2800ea);
  background-origin: border-box;
  border: 3px double #0000;
}

.image-upload-show {
  background: #fafcfe66;
  padding: 10px;
  display: flex;
  align-items: center;
  width: 265px;
  justify-content: center;
  height: 265px;
  position: relative;
  border-radius: 13px;
}

.image-upload-main {
  background: #ffffff66;
  padding: 30px 30px;
  box-shadow: 0px 0px 2px 0px #00000040;
  border-radius: 20px;
}

.upload-desc input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.upload-desc button {
  position: relative;
}

.image-upload-show .upload-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 20px;
}

.upload-desc h3 {
  font-size: 24px;
  color: #2b3674;
  line-height: 1.4;
  font-weight: 600;
}

.upload-desc p {
  color: #a3aed0;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 52px;
  line-height: 25px;
}

.cmn-select {
  background: #bbc6ff !important;
  color: #000 !important;
  font-weight: 600 !important;
  background-clip: padding-box, border-box !important;
  background-image: linear-gradient(#bbc6ff, #bbc6ff),
    linear-gradient(258deg, #d200a5, #2800ea) !important;
  background-origin: border-box !important;
  border: 2px double #0000 !important;
  font-size: 13px !important;
  border-radius: 10px !important;
  padding: 9px 10px !important;
  appearance: auto !important;
}

.cmn-pink {
  background: #d200a5;
  color: #fff;
  border: none;
  padding: 10px 24px;
  font-size: 13px;
  border-radius: 35px;
}

.f-22 {
  font-size: 22px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.classes-inner p {
  margin: 0;
  color: #a3aed0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: right;
}

.classes-inner h3 {
  margin-bottom: 4px;
  color: #a3aed0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
}

/* .classes-inner h2 {
  margin: 0;
  color: #2b3674;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
} */
.classes-inner {
  padding: 20px 20px;
  background: #ffffff66;
  box-shadow: 0px 18px 40px 0px #7090b01f;
  border-radius: 16px;
  cursor: pointer;
}

.classes-main {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.classes-main .classes-inner {
  width: 170px;
}

.pink-border {
  color: #000;
  border-radius: 21px;
  font-size: 14px;
  padding: 10px 23px;
  border: 2px solid #d200a5;
  font-weight: 600;
  background-color: #fff;
}

.class-detail-sec {
  background: #ffffff66;
  padding: 15px 20px;
  border-radius: 16px;
}

.class-detail-sec h2 {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #2b3674;
}

.blue-border {
  font-family: Raleway;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  background: #fff;
  border-radius: 34px;
  padding: 3px 30px;
  font-size: 14px;
  border: 2px solid #bde3ff;
  height: 50px;
}

.students-list img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 15px;
}

.students-list h3 {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.78px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #1b2559;
  margin-top: 10px;
  margin-bottom: 0;
}

.students-list p {
  font-size: 16px;
  font-weight: 400;
  line-height: 16.44px;
  margin-top: 10px;
  text-align: center;
  color: #606060;
}

.data-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.data-not-found-cmn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #a3aed0;
  font-size: 14px;
  margin: 0;
  text-align: center;
}

.comn-modal-set.create .modal-content {
  height: 100%;
  width: 100%;
}

.students-main {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0px;
}

.students-main .students-list {
  width: 170px;
  text-align: center;
}

.students-main .students-list img {
  height: 145px;
}

.cmn-card-color {
  background: #ffffff66;
  padding: 15px 20px;
  border-radius: 16px;
  position: relative;
  /* height: 440px; */
}

.cmn-card-color-dashboard {
  padding: 15px 0px;
  border-radius: 16px;
  position: relative;
  /* height: 440px; */
}

.student-view {
  text-align: center;
}

.student-view img {
  width: 87px;
  height: 87px;
  border-radius: 45px;
  object-fit: cover;
}

.student-view h2 {
  font-family: Raleway;
  font-size: 20px;
  font-weight: 700;
  margin-top: 8px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #2b3674;
  margin-bottom: 0;
}

.student-view p {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  letter-spacing: 0px;
  text-align: center;
  color: #a3aed0;
  word-spacing: 1px;
}

.students-dates {
  display: flex;
  justify-content: center;
  gap: 45px;
  margin: 40px 0;
}

.students-dates h3 {
  font-family: Raleway;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #2b3674;
}

.students-dates p {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #a3aed0;
}

.reset-pass {
  box-shadow: 0px 1px 4px 0px #00000040;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  background: #fff;
  letter-spacing: -0.02em;
  text-align: center;
  border: none;
  padding: 8px 30px;
  color: #4318ff;
  border-radius: 31px;
}

.student-view .edit-btn img {
  width: 20px;
  height: 20px;
  border-radius: 0px !important;
  object-fit: none !important;
}

.f-24 {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #2b3674;
}

.left-side.hide .toggle-icon {
  left: 10px;
}

.search-history-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-history-main h3 {
  color: #2b3674;
  font-size: 13px;
  font-weight: 600;
  margin: 0;
}

.search-history-main p {
  margin: 0;
  font-size: 14px;
  color: #a3aed0;
}

.Review-history img {
  width: 35px;
  height: 35px;
  border-radius: 10px;
  min-width: 35px !important;
  object-fit: cover;
}

.Review-history h3 {
  color: #2b3674;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.Review-history p {
  color: #a3aed0;
  font-size: 14px;
  margin: 0;
}

.browse-main.collection ul li {
  width: auto;
}

a.cmn-pink {
  background: #d200a5;
  color: #fff;
  border: none;
  display: block;
  padding: 10px 24px;
  font-size: 13px;
  margin: 10px 0;
  border-radius: 35px;
  text-decoration: none;
  margin: auto;
}

.student-view label.form-label {
  width: 100%;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #2b3674;
}

.student-view input {
  background-color: #bbc6ff;
  border-radius: 10px;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  padding: 10px 13px;
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#bbc6ff, #bbc6ff),
    linear-gradient(258deg, #d200a5, #2800ea);
  background-origin: border-box;
  border: 2px double #0000;
}

.student-view select {
  background-color: #bbc6ff;
  border-radius: 10px;
  font-size: 13px;
  color: #000;
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#bbc6ff, #bbc6ff),
    linear-gradient(258deg, #d200a5, #2800ea);
  background-origin: border-box;
  border: 2px double #0000;
  font-weight: 600;
  padding: 10px 13px;
}

.student-view textarea {
  background-color: #bbc6ff;
  border-radius: 10px;
  font-size: 13px;
  color: #000;
  font-weight: 600;
  padding: 10px 13px;
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#bbc6ff, #bbc6ff),
    linear-gradient(258deg, #d200a5, #2800ea);
  background-origin: border-box;
  border: 2px double #0000;
}

.recharts-wrapper.Bar-chart {
  width: 100% !important;
  height: 238px !important;
}

.details-chart p {
  margin: 0;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #a3aed0;
}

.details-chart h3 {
  font-family: Raleway;
  font-size: 34px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #2b3674;
}

.details-chart h3 span {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #a3aed0;
}

.details-chart input {
  background: #f4f7fe;
  border: none;
  padding: 10px 13px;
  border-radius: 10px;
  font-size: 12px;
  color: #a3aed0;
}

.students-list.active {
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#ccdeff, #ccdfff),
    linear-gradient(258deg, #d200a5, #2800ea);
  background-origin: border-box;
  border: 2px double #0000;
  border-radius: 18px;
}

.collection-list.active {
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#ccdeff, #ccdfff),
    linear-gradient(258deg, #d200a5, #2800ea);
  background-origin: border-box;
  border: 4px double #0000;
  border-radius: 18px;
}


.classes-inner.active {
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#e1ecff, #e1ecff),
    linear-gradient(258deg, #d200a5, #2800ea);
  background-origin: border-box;
  border: 2px double #0000;
}

.user-pro-search img {
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 0;
}

.kpi-discuss {
  background: #ffffff66;
  width: 240px;
  display: flex;
  color: #2b3674;
  font-weight: 500;
  justify-content: center;
  height: 80px;
  padding: 0px 31px 0px 30px;
  border-radius: 20px;
  align-items: center;
}

.main-dash {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-add {
  height: 290px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0px 15px;
}

.scroll-add::-webkit-scrollbar {
  display: none;
}

.Search.History {
  height: 400px;
  overflow-y: scroll;
}

.Search.History::-webkit-scrollbar {
  display: none;
}

.Review-history {
  height: 340px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: normal;
  /* justify-content: space-between; */
}

.Review-history::-webkit-scrollbar {
  display: none;
}

.top-fix-sec {
  position: fixed;
  left: 0;
  right: 27px;
  top: 0;
  z-index: 11;
  padding: 10px 0;
  background: #d2e7ff;
  width: 100%;
}

.video-scroll-section {
  height: 88vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.video-scroll-section::-webkit-scrollbar {
  display: none;
}

.student-view.teacher img {
  border-radius: 0;
}

.class-detail-sec.teacher .scroll-add {
  height: 495px;
}

.cmn-card-color.teacher .scroll-add {
  height: 495px;
}

.results-sec button {
  background: #d200a5;
  border: none;
  padding: 6px 30px;
  border-radius: 10px;
  margin-left: 20px;
}

.results-sec input[type="search"] {
  background-color: #f4f7fe;
  border: none;
  padding: 5px 30px;
  background-position: left 10px center;
  background-repeat: no-repeat;
  font-weight: 400;
  font-size: 14px;
  border-radius: 36px;
  color: #8f9bba;
}

.results-sec input[type="search"] {
  background-image: url("../Images/Search\ Icon.svg");
}

table.table-cmn.table.table-sm img {
  width: 35px;
  height: 35px;
  border-radius: 26px;
}

button.edit-btn {
  position: absolute;
  top: 13px;
  right: 20px;
  background: none;
  border: none;
}

button.setting-pro {
  position: absolute;
  top: 13px;
  right: 20px;
  border: none;
  background: #d200a5;
  border-radius: 35px;
  color: #fff;
  display: block;
  font-size: 13px;
  margin: auto;
  padding: 10px 24px;
  text-decoration: none;
}

.edit-btn img {
  width: 20px;
  height: 20px;
}

.collection-upld {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0;
}

.modal-close-btn {
  background: #2b3674 !important;
  border: 0 !important;
  padding: 10px 40px !important;
  border-radius: 50px !important;
  font-size: 14px !important;
}

.browse-main a img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 20px;
}

.video-sec video {
  width: 100% !important;
  height: 80vh !important;
  object-fit: cover;
  border-radius: 20px;
  transition: 0.5s ease;
}

.video-sec img {
  width: 400px !important;
  height: 80vh !important;
  object-fit: cover;
  border-radius: 20px;
  transition: 0.5s ease;
}

.video-icon-set div {
  height: unset !important;
  width: unset !important;
}

/* .video-side-btns {
  position: absolute;
  bottom: 0;
  right: 0;
} */
.video-side-btns {
  z-index: 111;
  margin-bottom: 60px;
}

.video-time-sec p {
  position: absolute;
  right: 12px;
  top: 75%;
  background-color: #ffffff;
  color: #9747ff !important;
  padding: 4px 10px;
  border-radius: 12px;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.video-sec {
  position: relative;
  height: 100%;
}

.video-player-controls button:first-child {
  position: absolute;
  left: 5px;
  top: 18px;
  background: none;
  border: none;
  /* display: none; */
}

.video-player-controls button:last-child {
  position: absolute;
  right: 0px;
  top: 18px;
  background: none;
  border: none;
}

.video-player-controls input {
  position: absolute;
  top: 21px;
  left: 30px;
}

.video-player-wrapper {
  position: relative;
}

.video-player-controls {
  position: absolute;
  top: 0;
  left: 45px;
}

.video-play-mute {
  display: flex;
  justify-content: space-between !important;
  gap: 20px;
  background: #d92cb500;
  width: 100%;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  margin-bottom: -44px;
  z-index: 999;
}

.video-play-mute button {
  background: none;
  border: 0;
}

.video-mute-btn svg {
  width: 20px !important;
  height: 20px !important;
}

.video-play-mute button svg {
  width: 20px !important;
  height: 20px !important;
}

.video-play-mute input[type="range"] {
  accent-color: #d200a5;
}

.inner-search-bar {
  width: 70.8% !important;
  margin-right: -10px;
}

.react-loading-skeleton {
  width: 400px !important;
  height: 810px !important;
  z-index: 1111;
  top: -4px;
  border-radius: 18px !important;
}

.spin-loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d2e7ff;
}

button.edit-btn.class {
  right: -51px;
  top: -2px;
}

.top-bar a img {
  width: 45px;
  height: 45px;
  margin-right: 5px;
  margin-top: 0px;
  object-fit: contain;
}

.video-show .video-player img {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s ease;
}

.loader {
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #d200a5;
  /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-main {
  width: 400px !important;
  height: 750px !important;
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  background: #b1b1b1;
  top: 0;

  border-radius: 20px;
}

.top-bottom-btns {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 52px;
  bottom: 0;
  top: 40%;
  gap: 90px;
  margin: auto;
}

.top-bottom-btns button {
  background: none;
  border: none;
}

.top-bottom-btns button svg {
  width: 40px;
  height: 40px;
}

.main-video-scroll::-webkit-scrollbar {
  display: none;
}

.help-popup {
  padding: 20px;
}

.filter-tab ul li.disabled {
  background: #d7d0d0 !important;
  border-color: #d7d0d0 !important;
}

.popup-upld-send {
  text-align: center;
}

.popup-upld-send img {
  height: 80px;
}

.popup-upld-send h2 {
  color: #d200a5;
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.4px;
}

.popup-upld-send p {
  color: var(--Secondary-Dark-Grey-500, #8f9bba);
  text-align: center;
  font-family: Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.video-main-sec {
}

.filter-tab-slide .slick-slide .inner-slide li {
  background: #fff;
  border-radius: 37px;
  font-size: 13px;
  padding: 6px 20px;
  display: flex;
  cursor: pointer;
  border: 2px solid #d200a5;
  align-items: center;
  font-weight: 600;
  list-style: none;
  width: fit-content !important;
}

.filter-tab-slide .slick-arrow.slick-next {
  right: 15px;
  background: #fff;
  border-radius: 37px;
  font-size: 13px;
  padding: 18px 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #d200a5;
  align-items: center;
  font-weight: 600;
  list-style: none;
  width: 40px;
  height: 30px;
  z-index: 1;
  justify-content: center;
}

.filter-tab-slide .slick-arrow.slick-prev {
  left: 7px;
  background: #fff;
  top: 0;
  border-radius: 37px;
  font-size: 13px;
  transform: rotatez(180deg);
  padding: 18px 13px;
  width: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #d200a5;
  align-items: center;
  font-weight: 600;
  list-style: none;

  height: 30px;
  z-index: 1;
  justify-content: center;
}

.filter-tab-slide .slick-arrow.slick-next::before {
  display: none;
}

.filter-tab-slide .slick-arrow.slick-prev::before {
  display: none;
}

.filter-tab-slide .slick-slide .inner-slide li.active-tab {
  background: #d200a5;
  color: #fff;
  border-color: #d200a5;
}

.filter-tab-slide {
  margin-top: 100px;
  padding: 0 60px;
}

.students-main-radius .students-list img {
  width: 150px;
  height: 150px !important;
  object-fit: cover;
  border-radius: 50%;
}

.carrier-options {
  display: flex;
  gap: 15px;
}

.inner-slide .slick-slide.slick-active.slick-current {
  width: fit-content !important;
}

/* .video-main-sec .carousel-control-next {
  right: 130px;
}
/* .video-main-sec .carousel-indicators {
  right: 90px !important;
} */
/* .video-scroll-section .carousel-control-next {
  right: calc(48% - 70px);
} */
.inner-slide-two {
  background: #fff;
  border-radius: 37px;
  font-size: 13px;
  padding: 6px 20px;
  display: flex;
  cursor: pointer;
  border: 2px solid #d200a5;
  align-items: center;
  font-weight: 600;
  list-style: none;
  color: #000;
  width: fit-content !important;
}

.active-tab.inner-slide-two {
  background: #d200a5;
  color: #fff;
  border-color: #d200a5;
}

.slick-slide {
  margin-right: 10px;
}

.video-scroll-section .slick-arrow {
  display: none !important;
}

.video-side-btns .btns-main {
  text-align: center;
}

/* .video-main-sec .carousel-control-prev {
  height: fit-content;
  top: 0;
  bottom: 0;
  margin: auto;
} */
/* .video-main-sec .carousel-control-next {
  height: fit-content;
  top: 0;
  bottom: 0;
  margin: auto;
} */
.video-play-mute h3 {
  font-size: 15px;
  margin: 0;
  color: #fff;
}

.video-player-wrapper .d-flex.align-items-center {
  gap: 16px;
}

/* rajinder css here  */

.main-dash.dash-card-ad {
  margin-top: 90px;
  height: 100% !important;
}

.dash-card-one img {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}

.dash-card-two img {
  width: 100px;
}

.dash-card-three img {
  width: 200px;
  height: 300px;
  border-radius: 10px;
  object-fit: cover;
}

.dash-card-one-bg {
  border-radius: 20px;
  background: #b85537;
  padding: 25px;
  margin-bottom: 20px;
}

.dash-card-two-bg {
  border-radius: 20px;
  background: #37b866;
  padding: 25px;
  margin-bottom: 20px;
}

.dash-card-three-bg {
  border-radius: 20px;
  background: #8a37b8;
  padding: 25px;
  margin-bottom: 20px;
}

.dash-card-one {
  background: #f5e3dd;
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  vertical-align: middle;
}

.dash-card-two {
  background: #dcf5e4;
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  vertical-align: middle;
}

.dash-card-three {
  background: #f5e3dd;
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  vertical-align: middle;
}

.dash-card-three p {
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.dash-card-one #chart > div {
  width: 400px;
}

.dash-card-two #chart > div {
  width: 400px;
}

.dash-card-three #chart > div {
  width: 400px;
}

.dash-card-one h4 {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.dash-card-one p {
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-top: 10px;
}

.dash-card-two h4 {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.dash-card-two h1 {
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-family: Raleway;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.25px;
}

.dash-card-three h4 {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.dash-card-one > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dash-card-two > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dash-card-three > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 0% !important;
  margin-bottom: 1rem;
  margin-left: 0% !important;
  width: 100%;
} */
/* rajinder css here  */

.ad-pad-match {
  padding: 0px 15px;
}

.main-reel-view {
  display: flex;
  align-items: flex-start;
  /* gap: 60px; */
  justify-content: space-around;
  position: relative;
}

.main-reel-view .video-content {
  text-align: left;
  width: 250px;
  margin-top: 60px;
}

.fixed-none {
  position: unset !important;
  overflow-y: scroll;
  height: 100vh;
}

.fixed-none::-webkit-scrollbar {
  display: none;
}

.double-qr-set {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
}

.double-qr-set .qr-code img {
  border-radius: 0 !important;
}

.details-chart > DIV {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.add-student-set .collection-sec {
  height: 480px !important;
  overflow-x: hidden;
  overflow-y: scroll;
}

.search-history-height-set {
  height: 370px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.top-bar a .dynamic-image-cover {
  object-fit: cover !important;
}

.details-chart > div h5 span {
  margin: 0;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #a3aed0;
}

.details-chart > div h5 {
  display: flex;
  align-items: end;
  gap: 5px;
  color: #2b3674;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.48px;
  margin-bottom: 0;
}

.details-chart > div input {
  /* width: 40% !important; */
}

.back-sign-pg {
  position: absolute;
  top: 30px;
  left: 20px;
}

.back-sign-pg a {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 8px 10px;
}

.sign-shape img {
  height: 200px;
}

.long-txt-head {
  line-height: 30px;
  width: 50%;
}

.all-learners-mail {
  font-size: 14px !important;
}

.all-learners-name {
  font-size: 14px !important;
}

.classes-inner h2 {
  color: #2b3674;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 16px;
  margin: 0;
  text-align: left;
  word-wrap: break-word;
}

.ad-height-pop {
  position: static;
  display: flex;
  justify-content: center;
  width: 100%;
  transform: none !important;
  height: 270px;
  align-items: center;
}

.custom-tooltip {
  background-color: #fff;
  padding: 10px;
  display: flex;
  gap: 10px;
  box-shadow: 0 0.5rem 1.2rem rgba(0, 0, 0, 0.2);
}

.downld-browse {
  position: absolute !important;
  top: 20px;
  right: 30px !important;
  display: flex !important;
  justify-content: end;
  margin: auto;
  z-index: 999;
}

.downld-browse svg path {
  fill: #d200a5;
}

.reset-contin-msg {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.reset-contin-msg svg {
  height: 200px;
  width: 200px;
  margin-bottom: 30px;
}

.reset-contin-msg p {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 20px;
}

.reset-contin-msg h2 {
  margin-bottom: 40px;
  font-size: 30px;
}

/* .download-cover svg path {
  fill: rgb(238, 231, 234);
} */

.most-popular-list .most-popular-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  margin-bottom: 15px;
  /* text-align: center; */
}

.most-popular-list .most-popular-item .view-history {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
}

.most-popular-item a {
  border-radius: 70px;
  background: #160071;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.total-views-chat h3 {
  color: #2b3674;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
  margin: 20px 0px;
}

/* .total-views-count {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
} */

.total-views-count {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}


.total-views-count-line {
  display: flex;
  align-items: center;
  gap: 10px;
}

.total-views-day {
  display: flex;
  gap: 10px;
  align-items: center;
}

.total-views-count-line p {
  color: #2b3674;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.28px;
  margin: 0;
}

.total-views-updown {
  display: flex;
  align-items: center;
  gap: 10px;
}

.total-views-count .total-views-updown {
  color: #05cd99 !important;
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}

.total-views-count .total-views-updown.down-graph {
  color: #f00 !important;
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}

/* .total-views-count .total-views-updown:last-child{
  color: #F00 !important;
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
} */

.top-year-group h3 {
  color: #2b3674;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
  margin-top: 20px;
}

.most-active-chart .head-set-chart {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #a3aed0 !important;
  font-family: Raleway;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.28px;
}

.most-active-chart > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.most-active-chart .chat-year-count p {
  color: #2b3674;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.28px;
}

.most-active-chart .chat-year-count .green {
  color: #05cd99;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.28px;
}

.most-active-chart .chat-year-count .yellow {
  color: #ffac33;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.28px;
}

.most-active-chart .chat-year-count .red {
  color: #05cd99;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.28px;
}

.dash-drop-ad {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dash-drop-ad button {
  border-radius: 30px;
  border: 1px solid #bde3ff;
  background: #f4f7fe;
  color: #2b3674;
  font-family: Raleway;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.24px;
  padding: 10px 15px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.dash-drop-ad .btn-check:checked + .btn,
.dash-drop-ad .btn.active,
.dash-drop-ad .btn.show,
.dash-drop-ad  .btn:hover ,
.dash-drop-ad .btn:first-child:active,
:not(.btn-check) + .btn:active {
  border-radius: 30px;
  border: 1px solid #bde3ff;
  background: #f4f7fe;
  color: #2b3674;
}

.dash-drop-ps {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.new-date-picker button {
  background: transparent;
}

/* .new-date-picker .react-datepicker-wrapper {
  width: 245px;
}

.react-datepicker__input-container input {
  max-width: 223px;
  width: 100%;
  background-color: #bbc6ff;
  border-radius: 16px;
  height: 39px;
  font-size: 13px;
  font-weight: 600;
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#bbc6ff, #bbc6ff), linear-gradient(258deg, #d200a5, #2800ea);
  background-origin: border-box;
  border: 2px double #0000;
  color: #000;
  line-height: 100%;
  letter-spacing: 0;
  text-align: center;
  outline: none;
}
.new-date-picker .react-datepicker__input-container {
  max-width: 243px;
} */
.new-date-picker input {
  background: #f4f7fe;
  border: none;
  padding: 10px 13px;
  border-radius: 10px;
  font-size: 12px;
  color: #a3aed0;
}


/* /////////////// */

.img-show-pop {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* padding: 10px; */
  position: relative;
  }
  
  .img-show-pop>div {
  display: flex;
  justify-content: end;
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 20px;
  }
  
  
  .img-show-pop img {
  height: 400px;
  width: 100%;
  margin: auto;
  object-fit: contain;
  background-color: #fff;
  padding: 50px;
  border-radius: 20px ;
  
  }
  
  .img-show-pop>div svg {
  background-color: #fff;
  padding: 5px;
  box-sizing: content-box;
  border-radius: 5px;
  cursor: pointer;
  }
  
  .pop-trans-set .modal-content {
  background-color: transparent !important;
  border: 0 !important;
  width: 560px;
  margin: auto;
  }
  
  .img-show-pop>div button {
  border: 0;
  background-color: transparent;
  }
  
  
  .fullScreenVideo {
    background: #d2e7ff;
}



.fullScreenVideo .short-container {
  scroll-padding-top: 5rem !important;
}


/* .fullScreenVideo  .reel {
  margin-bottom: 100px;
} */

.fullscreenStateAnother .video {
  height: 90vh;
  width: 500px;
}

.fullscreenStateAnother.video-sec video {
  height: 90vh !important;
}
.fullScreanReport{
  width: 100%;
}

.flag-icon {
  display: flex;
  flex-direction: row !important;
  gap: 11px;
  align-items: center;

}

.flag-icon p {
  margin-bottom: 0px;
}

.caption-sub{
  display: flex;
  flex-direction: row !important;
  gap: 11px;
  align-items: center;

}

.caption-sub p {
  margin-bottom: 0px;
}

.caption-text .bs-popover-bottom {
  cursor: auto !important;
  padding: 0px;
}

.caption-text .caption-sub {
  padding: 7px 15px;
  cursor: pointer;
}

.caption-text .flag-icon {
  padding: 7px 15px ; 
  cursor: pointer;

}

.caption-sub:hover {
  background-image: linear-gradient(#bbc6ff, #bbc6ff), linear-gradient(258deg, #d200a5, #2800ea);
}

.flag-icon:hover {

  background-image: linear-gradient(#bbc6ff, #bbc6ff), linear-gradient(258deg, #d200a5, #2800ea);
}

.caption-text div span{
  margin: 0px !important;
}

.flag-icon {
align-items: flex-start !important;
justify-content: flex-start !important;
}

.remove-div {
  display: block !important;
}

/* ========================================================================================= */
  .video {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; 
}

.circular-loader {
  position: relative;
  width: 100px; 
  height: 100px;
}

.progress-ring {
  transform: rotate(-90deg); 
}

.progress-ring__circle {
  transition: stroke-dashoffset 0.35s ease; 
}

.percentage-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5); 
}

.video-player {
  position: relative;
}  

.collection-box {
  display: flex;
  gap: 17px;
}

.serch-teacher {
  position: relative;
  justify-content: center;
}

.sendbtn {
  text-align: end;
  position: absolute;
  right: 0px;
}

button.save-button {
  width: 100%;
  max-width: 107px;
}